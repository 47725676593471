import React from 'react'
import Navbar from '../components/Navbar/NavBar'
import Footer from '../components/Footer/Footer'
import { Outlet } from 'react-router-dom'

const RootLayout = () => {

  return (
    <div >
      <Navbar />
      <main className='mt-20 bg-white'>
        <Outlet />
      </main>
      <Footer  />
    </div>
  )
}

export default RootLayout
